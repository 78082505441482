<template>
  <div class="modal-content">
    <div class="user_detail-error" v-if="user === null || !user.id || !subscription">
      存在しないユーザです
    </div>
    <div class="user_detail" v-else>
      <div class="user_photo"><img :src="user.image | imageUrl" alt="ユーザ画像"></div>
      <div class="user_nickname">{{ user.nick }}</div>
      <div class="user_fan_career" v-if="elapsed_time">ファン歴：{{ elapsed_time }}</div>
      <SnsLink :user="user"></SnsLink>
      <div>{{ user.comment }}</div>

<!--      <MyAlbum class="list_user" :album="album" @modalOpenPreview="modalOpenPreview($event)"></MyAlbum>-->

      <MyOrders :user="user" :talkRoom="talkRoom"></MyOrders>

      <MyDigitalContents :user="user" :talkRoom="talkRoom" @modalOpenPreview="modalOpenPreview"></MyDigitalContents>

      <!-- アルバム -->
      <div class="acd">
        <input id="check4" type="checkbox" class="acd-hidden">
        <label for="check4" class="acd-open">アルバム</label>
        <label class="acd-close">
          <MyAlbum class="list_album" :album="album" @modalOpenPreview="modalOpenPreview"></MyAlbum>
        </label>
      </div>
    </div><!--.user_detail-->
  </div>
</template>

<script>
import SnsLink from '@/components/SnsLink';
import MyAlbum from '@/components/MyAlbum';
import MyOrders from '@/components/MyOrders';
import MyDigitalContents from '@/components/MyDigitalContents';
import moment from 'moment';
import axios from 'axios';
export default {
  name: 'UserProfile',
  components: {
    SnsLink, MyAlbum, MyOrders, MyDigitalContents
  },
  props: {
    talkRoom: Object
  },
  data () {
    return {
      album: []
    }
  },
  computed: {
    user () {
      return this.$store.state.talkRoom.selectedUser;
    },
    subscription () {
      if (this.user.artist_id === this.talkRoom.artist_id) return true;
      const talk_room = this.user.talk_rooms.find(room => room.id === this.talkRoom.id);
      if (!talk_room) {
        //  退会済み
        return false;
      }
      return talk_room.subscription;
    },
    elapsed_time () {
      const from = moment(this.subscription.authorized_at);
      const now = moment();
      const year = now.diff(from, 'year');
      const month = now.subtract(year, 'year').diff(from, 'month');
      const day = now.subtract(month, 'month').diff(from, 'day');
      if (year && month) {
        return `${year}年${month}ヵ月`;
      } else if (year && !month) {
        return `${year}年`;
      } else if (month && day) {
        return `${month}ヵ月${day}日`;
      } else if (month && !day) {
        return `${month}ヵ月`;
      } else {
        return `${day}日`;
      }
    },
  },
  async created () {
    // アルバム情報
    const albumResponse = await axios.get(`https://${process.env.VUE_APP_CHAT_SERVER}/user/${this.user.id}/album`);
    this.album = albumResponse.data;
  },
  methods: {
    modalOpenPreview (attachment) {
      this.$store.commit('setTalkRoomPreviewImage', attachment);
      this.$nextTick(() => {
        this.$modal.show('modal-preview');
      });
    },
  }
}
</script>

<style scoped>
.modal-content {
  overflow-y: scroll;
}
</style>
