<template>
    <li :class="{'master': isMaster}">
      <iframe
          v-if="fileType==='youtube'"
          width="auto"
          height="100%"
          :src="attachment | toEmbedVideoUrl"
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>

          <pdf v-if="fileType == 'pdf'" :src="attachment" @click="$emit('modalOpenPreview', attachment)"></pdf >

          <img v-if="fileType === 'image'" :src="attachment" alt="" @click="$emit('modalOpenPreview', attachment)">
      <audio v-if="fileType==='audio' || fileType==='pre_audio'" controls="controls">
        <source :src="attachment">
        音声を再生するには、audioタグをサポートしたブラウザが必要です。
      </audio>
      <div v-if="attachment && fileType==='pre_video'" class="video_thumbnail">
        <div>
          <img data-v-a4796bb0="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAELElEQVRYhcWZXWwUVRTHf1uWbQ0FoUANCeJWt0UqRhQNRAwtBoGWF0ptjIkQo8RExAd5UGOij/piokZMn4xaGhO1qPhBi0r40CYa40dirbZWdxMbleq2xRZoC+WYM5kly9y7tjM7W37JSXbvnDnnv/feOffO3YiIEBKlwJVADBh2LZTgUaNleiwDbgU2ArcBK4A5njvHgF7ga+AI0O4K94/25DStSkSeE5GkBOOMiLwhIqt85HTMaLDYEhFpFZHJgOK8TLrxrrbkspq1McsaRCRtpAmHUyLSZMlpmNGQZY+LyIUCCcymWUSilvxTDvcTRqjC8qGIxCw6coqsm6Ee9HJQRGZZ9OCtk1cB3UBZWMXTJ83Abu8tRZ7vz19GgcrDwIPexuyevAn4Ttu8Tv8MDXHo2HH6UinGxieMyEEoKY6RiMepr61h0YIF2SHOAKuBn20i3waavAn/HhxkX8t+zo6NhyLOyxUlxezZuYPFZZcM4AmgxitS52K/bZlsee99unp6WX5tBU31dcwrLTUSBeHf0VHeOdROz29JVi6vYmfDNm8YbThI1py81yZQ0SFWwhSoaCyNqfQmU8Z14MnMh4zIRsPFJTMHwxSYLVSZmLDO87VANW7vqecaw8UH3X19vNvxiXND7do13H7LzRQVeQtHILTzujXSOmB2PpFUoM4xtQ8+O8JLr7eQ6u83/AJwJ+5wr8o3kopT7r97O2Xz5/PnwADNrW/y1kcfM3L6tOHvAy1FznBfH8ZPVqoTCSrjcY59+ZVj33T96EyFe7ZupboyYfhPg7m6uGhPloclUpkdjXLXHevYu+sBViSuc+prW8dhw88Hjsh5YYrMEHHMWLwCiYyG9bKU4dz58xeHWz/ritK4eZPh54PxaOCXIws/9f3qPN3p4WGnD1evvIH6DbXMneN9R/PFsIo8ma84FaFP8WttB5zvS8rLadi0kfjSpYavT87qch1194950bhlMwc6DhOJRMIu5j3ApIr83LjkEy0vAUvMVHTiFvNvgb9yOeu+j6yCHSaZQh+LxXJFbc+IvKA7MuOyi25Mlbb2jnxXj0s4NTLibNWUqoq4cR1IuycfF/eTemzSZ1vDB9JpXtnfWtBN7yM77qN84ULvpReAvXh25i8De4wo7u68/fgJfkmmGLdvq3xTHItRWRGnrma9d1euTLjLddIrUtfJH4BrQlGRH/uARzMRvK+0G4BPgVmXUeAfwI3AYKbBW8yOAk8Zt80c2mMPZQt0sJ0YiMizxvnCzPC0RUtOkWqPhXjcNx1eFZGIRcf/ilTb7h7RFZoXcwmc7iFqQkQ6CyRyVER2WXL6FqlWJCK7ReSkkSYYE+7R9DJLLsOMhimsxP3lnQHna5eIPOPnKNp29OeHxcB699+HKmCR+yqii8I5YMitebrcfg98AfzuOwvwH8oc5KFiwG5RAAAAAElFTkSuQmCC" alt="動画投稿ボタン">
          <div>{{ Math.floor(this.file.size / 1024 / 1024 * 10 + 0.5) / 10.0 }}M</div>
          <div>{{ this.file.type.substr(this.file.type.indexOf('/')+1) }}</div>
        </div>
<!--        <canvas ref="thumbnailView"></canvas>-->
        <!--        <video ref="video" controls></video>-->
      </div>
      <label class="switch" v-if="openSell && attachment && isMaster && (fileType === 'image' || fileType === 'pre_video')">
        <input class="switch-input" type="checkbox" v-model="checked" @change="$emit('update:isDigitalItem', checked)">
        <span class="switch-label" data-on="有料" data-off="無料"></span>
        <span class="switch-handle"></span>
      </label>
      <div class="trash" v-if="attachment">
        <a @click="$emit('removeAttachment')">
          <img src="@/assets/img/talk/icon_trash.png" alt="ゴミ箱ボタン">
        </a>
      </div>
    </li>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'PostAttachmentThumb',
  components: {
    pdf
  },
  props: {
    attachment: String,
    file: File,
    talk_room: Object,
    isDigitalItem: Boolean,
  },
  data () {
    return {
      checked: false,
      openSell: true,
      src: this.attachment, // PDFのデータURLまたはパス
      error: null,
      numPages: null,
      currentPage: 0,
			pageCount: 0,
    }
  },
  computed: {
    fileType () {
      if (/^https?:\/\/(www\.)?youtu\.?be(\.com)?/.test(this.attachment)) {
        return 'youtube';
      } else if (/^data:audio\//.test(this.attachment)) {
          return 'pre_audio';
      } else if (/\.audio\.\w+$/.test(this.attachment)) {
        return 'audio';
      } else if (/^data:video\//.test(this.attachment) || (typeof this.attachment === 'object' && this.attachment.type === 'video/mp4')) {
        return 'pre_video';
      } else if (/\.image\.\w+$/.test(this.attachment)) {
        return 'image';
      } else if (/^data:application\/pdf/.test(this.attachment)) { // PDFデータURLをチェック
        return 'pdf';
      }
      return 'image';
    },
    isMaster () {
        return (this.talk_room.artist_id === this.$store.state.loginUser.artist_id);
    },
  },
  filters: {
    toEmbedVideoUrl(url) {
      let id;
      const found = url.match(/https:\/\/youtu\.be\/([\w_.!*'()-]{11})/i);
      const found_shorts = url.match(/https:\/\/youtube\.com\/shorts\/([\w_.!*'()-]{11})/i);
      if (found && found[1]) {
        id = found[1];
      } else if (found_shorts && found_shorts[1]) {
          id = found_shorts[1];
      } else {
        const getParameterByName = (name, url) => {
          let queryString = url.split('?');
          if(queryString.length >= 2){
            let paras = queryString[1].split('&');
            for(let i = 0; i < paras.length; i++){
              let eachPara = paras[i].split('=');
              if(eachPara[0] == name) return eachPara[1];
            }
          }
        }
        id = getParameterByName('v', url);
      }
      return 'https://www.youtube.com/embed/'+id;
    },
  },
  mounted() {
    // if (this.fileType === 'pre_video') {
    //   this.$refs.video.src = URL.createObjectURL(this.file);

      // const videoRenderer = document.createElement('video');
      // const thumbnailView = this.$refs.thumbnailView;
      // videoRenderer.src = window.URL.createObjectURL(this.attachment);
      // videoRenderer.addEventListener('loadedmetadata', () => {
      //   console.log(videoRenderer);
      //   // videoRendererLoaded = true;
      //   thumbnailView.width = videoRenderer.videoWidth;
      //   thumbnailView.height = videoRenderer.videoHeight;
      //   thumbnailView.getContext('2d').drawImage(videoRenderer, 0, 0);
      // });
    // }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
iframe, audio {
  height: 100%;
  max-width: 100%;
}
video {
  height: 100%;
  max-width: 100%;
}
.master {
  position: relative;
  margin: 0 0 80px 0;
  width: calc(25% - 20px / 4);
}
.trash {
  z-index: 20;
  position: absolute;
  bottom: -15px;
  right: 0;
  width: 10px;
  height: 12px;
}
.master .trash {
  z-index: 20;
  position: absolute;
  bottom: -50px;
  left: 50%;
  margin-left: -5px;
  width: 10px;
  height: 12px;
}
.switch {
  z-index: 20;
  position: absolute;
  bottom: -25px;
  left: 50%;
  margin-left: -28px;
  display: inline-block;
  width: 56px;
  height: 20px;
  padding: 3px;
  border-radius: 18px;
  cursor: pointer;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  color: #fff;
  font-size: 10rem;
  font-weight: normal;
  text-transform: uppercase;
  background: #bababa;
  border-radius: inherit;
  transition: 0.15s ease-out;
}
.switch-label::before, .switch-label::after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  transition: inherit;
}
.switch-label::before {
  content: attr(data-off);
  right: 11px;
}
.switch-label::after {
  content: attr(data-on);
  left: 11px;
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: #1ba79a;
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 22%;
  margin: -3px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 6px;
}
.switch-input:checked ~ .switch-handle::before {
  left: 78%;
}
.video_thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
  > div {
    text-align: center;
  }
}

</style>
