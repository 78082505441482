<template>
  <div>
    <template v-if="type === 'image'">
      <img
          v-if="!isDigital || (isDigital && !haveBoughtDigitalContent)"
          :src="attachment"
          width="600"
          height="400"
          class="img-fluid w-100 mx-auto"
          blank="true"
      >
      <img
          v-if="isDigital && haveBoughtDigitalContent"
          :src="digitalSource"
          width="600"
          height="400"
          class="img-fluid w-100 mx-auto"
          blank="true"
      >
    </template>
    <template v-if="type === 'pdf'">
      <object :data="attachment | thumbnail(width)" type="application/pdf" style="height:100%; width:100%;"></object>
    </template>
    <template v-if="type === 'video'">
      <video
          v-if="!isDigital"
          :src="videoUrl"
          class="img-fluid w-100 mx-auto"
          :poster="attachment"
          controls
      >
        <p>ブラウザがvideoに対応していません。</p>
      </video>
      <video
          v-if="isDigital && haveBoughtDigitalContent"
          :src="digitalSource"
          class="img-fluid w-100 mx-auto"
          :poster="attachment.replace('.mosaic', '')"
          controls
      >
        <p>ブラウザがvideoに対応していません</p>
      </video>
      <img
          v-if="isDigital && !haveBoughtDigitalContent"
          :src="attachment"
          width="600"
          height="400"
          class="img-fluid w-100 mx-auto"
          blank="true"
      >
    </template>
  </div>

</template>

<script>
import 'swiper/swiper-bundle.css'
import moment from 'moment';

export default {
  name: 'ModalPreviewImageSlide',
  components: {
  },
  props: {
    attachment: String,
    isDigital: Boolean,
  },
  computed: {
    storedDigitalContents () {
      return this.$store.state.storedDigitalContents;
    },
    type () {
      if (this.attachment.toLowerCase().endsWith('.pdf')) {
        return 'pdf';
      } else {
        return this.attachment.indexOf('.video.') === -1 ? 'image' : 'video'
      }
    },
    digitalSource () {
      const matches = this.attachment.match(/\/storage\/uploads\/talk_room\/(.*)/);
      if (!matches) return;
      let id = matches[1].replace('.mosaic.', '.');
      if (this.attachment.indexOf('.video.')) {
        id = id.replace('.thumb.png', '');
      }
      const time = moment().unix();
      const md5 = require("md5")
      const secret = 'message';
      const checksum = md5(id + time + secret);
      return process.env.VUE_APP_API_ROOT + `../image.php?id=${id}&time=${time}&checksum=${checksum}`;
    },
    haveBoughtDigitalContent () {
      if (!this.storedDigitalContents || !this.storedDigitalContents.length) return false;
      return this.storedDigitalContents.indexOf(this.attachment) > -1;
    },
    videoUrl() {
      let url = this.attachment.replace('.thumb.png', '');
      if (url.startsWith('http://')) {
        url = url.replace('http://', 'https://');
      }
      return url;
    }
  },
  methods: {
    getImageUrl (imageId) {
      return `https://picsum.photos/600/400/?image=${imageId}`
    },
  }
};
</script>

<style lang="scss" scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
}
.w-100 {
  width: 100%;
}
.ml-auto, .mx-auto {
  margin-left: auto;
}
.mr-auto, .mx-auto {
  margin-right: auto;
}
</style>