<template>
  <article id="wrap">
    <header>
      <ul class="page_head head_green">
        <router-link class="page_head_link" :to="`/talk/${talkRoom.uuid}`" replace>
          <li class="menuL"><a class="close" @click="goBack"><img src="@/assets/img/icon_back_white.png"></a></li>
        </router-link>
        <li class="menuC"><h1 class="page_head_h1"> コメントをしてみよう! </h1></li>
        <li class="menuR"></li>
      </ul>
    </header>
    <div class="panel_area" id="panel_area">
      <TalkRoomRemark v-for="remark in remarks"
                      v-bind="remark"
                      :talk_room="talkRoom"
                      :likes="likes"
                      :key="`comment-${remark.id}`"
                      :socket="socket"
                      :isCommentRoot="remark.id === chat_id"
                      :is-last="true"
                      @modalOpenPostEdit="modalOpenPostEdit(remark)"
                      @update:like_count="remark.like_count=$event"
                      @pushLike="pushLike"
      ></TalkRoomRemark>
    </div><!--.panel_area-->

  </article>
</template>

<script>
import TalkRoomRemark from '@/components/TalkRoom/Remark';
import axios from 'axios';
export default {
  name: 'ChatComment',
  components: { TalkRoomRemark},
  props: {
    talkRoom: Object,
    socket: Object,
    likes: Object,
  },
  data() {
    return {
      remarks: [],
      selectedRemark: null,
      likeWaitingList: [],
      timer: null,
    }
  },
  computed: {
    chat_id () {
      return this.$route.params.chat_id;
    }
  },
  created () {
    this.initialize();
  },
  beforeDestroy (){
    this.socket.removeListener('message', this.socketOnMessage);
    this.socket.removeListener('like', this.socketOnLike);
  },
  methods: {
    async initialize() {
      this.socketInitialize();

      // mongoDBから取得
      const resMessages = await axios.get(
          `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${this.talkRoom.id}/messages/${this.chat_id}`);
      this.remarks = resMessages.data.messages;
    },
    goBack () {
      this.$modal.hide('modal-comment');
      // window.history.go(-1)
      this.$router.push({name: 'talk', params: {talk_room: this.talkRoom.id}});
    },
    socketInitialize() {
      this.socket.on('message', this.socketOnMessage);
      this.socket.on('like', this.socketOnLike);
    },
    scrollBottom() {
      this.$nextTick(() => {
        const panelArea = document.querySelector('.modal-comment .vm--modal');
        var bottom = panelArea.scrollHeight;
        panelArea.scrollTo({top: bottom, behavior: 'smooth'});
      });
    },
    socketOnMessage(message) {
      if (message.parent_id !== this.chat_id) return;
      const remark = this.remarks.find(remark => remark.id === message.id);
      if (remark) {
        // 未読リストメッセージの更新
        this.remarks.splice(this.remarks.indexOf(remark), 1, message);
      } else {
        // 新規メッセージ
        this.remarks.push(message);
        this.scrollBottom();
      }
    },
    socketOnLike(message) {
      if (message.chat_id) {
        let existRemark;
        existRemark = this.remarks.find(remark => remark.id === message.chat_id);
        if (existRemark) {
          const remark = {...existRemark, like_count: existRemark.like_count + message.add};
          this.remarks.splice(this.remarks.indexOf(existRemark), 1, remark);
        }
        this.$emit('updateLikes', message);
      }
    },
    debounce (fn, interval) {
      // console.log('debounce');
      clearTimeout(this.timer)
      this.timer = setTimeout(function() {
        fn()
      }, interval)
    },
    pushLike (param) {
      console.log('pushLike');
      this.likeWaitingList.push(param);
      this.debounce(this.sendLike, 1000);
    },
    sendLike () {
      // console.log(this.likeWaitingList);
      axios.post(`https://${process.env.VUE_APP_CHAT_SERVER}/likes`, this.likeWaitingList)
      this.likeWaitingList.forEach((obj) => {
        this.socket.emit('like', {
          talk_room_id: this.talkRoom.id,
          user_id: this.$store.state.loginUser.id,
          chat_id: obj.chat_id,
          add: 1,
        });
      });
      this.likeWaitingList = [];
    }
  }
}
</script>

<style lang="scss" scoped>
#wrap {
  min-height: auto;
}
.close {
  font-size: 33px;
}
header {
  position: sticky;
  top: 0;
  z-index:100;
}
</style>
