<template>
  <div class="talk_images" v-if="attachments && attachments.length > 0">
    <AttachmentThumb
        v-for="(attachment, index) in attachments" :key="attachment"
        :attachmentsLength="attachments.length"
        :attachment="attachment"
        :whole="attachments.length === 1"
        :class="{whole: attachments.length === 1}"
        :isDigitalItem="digital_items && digital_items[index]"
        :haveBoughtDigitalContents="haveBoughtDigitalContents"
        @modalOpenPreview="$emit('modalOpenPreview', $event)"
    ></AttachmentThumb>
  </div>
</template>

<script>
import AttachmentThumb from '@/components/TalkRoom/AttachmentThumb';
export default {
  name: 'AttachmentThumbs',
  components: { AttachmentThumb },
  props: {
    attachments: Array,
    digital_items: Array,
    haveBoughtDigitalContents: Boolean,
  },
  data () {
    return {}
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
.talk_images {
  .whole {
    width: 100%;
    height: 220px;
    text-align: center;
  }
}
</style>
