<template>
  <div class="img_preview">
      <swiper
          :slides-per-view="1"
          :space-between="30"
          :loop="false"
          :pagination="false"
          :navigation="false"
          @swiper="onSwiper"
      >
        <swiper-slide v-for="(attachment, n) in selectedRemarkAttachments" :key="attachment" class="swiper-slide">
          <ModalPreviewImageSlide
              :attachment="attachment"
              :isDigital="selectedRemark.digital_items && selectedRemark.digital_items[n]"
          />
        </swiper-slide>
      </swiper>
  </div>

</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import ModalPreviewImageSlide from '@/components/TalkRoom/ModalPreviewImageSlide';
SwiperCore.use([Navigation, Pagination])

export default {
  name: 'ModalPreviewImage',
  components: {
    Swiper,
    SwiperSlide,
    ModalPreviewImageSlide
  },
  computed: {
    selectedRemark() {
      return this.$store.state.talkRoom.selectedRemark;
    },
    selectedRemarkAttachments() {
      if (this.$store.state.talkRoom.selectedRemark.attachments) {
        return this.$store.state.talkRoom.selectedRemark.attachments;
      }
      return [];
    },
  },
  methods: {
    getImageUrl (imageId) {
      return `https://picsum.photos/600/400/?image=${imageId}`
    },
    onSwiper (swiper) {
      if (this.$store.state.talkRoom.preview_image) {
        const idx = this.$store.state.talkRoom.selectedRemark.attachments.indexOf(this.$store.state.talkRoom.preview_image);
        swiper.activeIndex=idx;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  text-align: center;
  justify-content: center;
  align-items: center;
  width:100% !important;
}
</style>