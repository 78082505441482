<template>
  <li>
    <div class="modal_buy_photo"><img :src="selectedPhoto | imageUrl" alt="モーダル商品画像"></div>
    <ul class="modal_buy_thumb">
      <li v-for="photo in photos" :key="photo" :class="{current: photo === selectedPhoto }" @click="selectedPhoto=photo">
        <img :src="photo | imageUrl" alt="モーダルサムネイル画像">
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    photos: Array,
  },
  data () {
    return {
      selectedPhoto: null
    }
  },
  created () {
    if (!this.photos.length) return;
    this.selectedPhoto = this.photos[0]
  }
}
</script>

<style scoped>
li {
  cursor: pointer;
}
</style>
