<template>
  <form class="modal-window">
    <div class="modal-content">
      <div class="txt_12">YoutubeのURLを入力してください</div>
      <div class="mb-20" :class="{ 'form-group--error': $v.videoUrl.$error }">
<!--            <div class="error" v-if="$v.videoUrl.$dirty && $v.videoUrl.$error">URLを正しくご入力ください</div>-->
        <input type="url" ref="videoUrl" placeholder="URLを入力してください"
               v-model="videoUrl" @input="inputVideoUrl">
      </div>
      <div class="bt_yes_no">
        <div class="bt_cancel"><a @click="$modal.hide('modal-post03')">キャンセル</a></div>
        <div class="bt_form">
          <button type="button" :class="{disabled: !$v.videoUrl.$dirty || $v.videoUrl.$error}"
                  @click="addYoutube" :disabled="!$v.videoUrl.$dirty || $v.videoUrl.$error">OK</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, maxLength, helpers} from 'vuelidate/lib/validators';
const regexpYoutube = /^https?:\/\/(www\.youtube\.com\/watch\?v=[\w_.!*'()-]{11}|youtu\.be\/[\w_.!*'()-]{11}|www\.youtube\.com\/v\/[\w_.!*'()-]{11}|youtube\.com\/shorts\/[\w_.!*'()-]{11})/i;

const regVideoUrl = helpers.regex('videoUrl', regexpYoutube);

export default {
  name: 'PostYoutube',
  components: {},
  data() {
    return {
      videoUrl: '',
      videoUrlValid: 0,
    };
  },
  mixins: [validationMixin],
  validations() {
    return {
      videoUrl: {
        required,
        maxLength: maxLength(200),
        regVideoUrl,
      },
    }
  },
  filters: {
    toEmbedVideoUrl(url) {
      const getParameterByName = (name, url) => {
        let queryString = url.split('?');
        if(queryString.length >= 2){
          let paras = queryString[1].split('&');
          for(let i = 0; i < paras.length; i++){
            let eachPara = paras[i].split('=');
            if(eachPara[0] == name) return eachPara[1];
          }
        }
      }
      const id = getParameterByName('v', url);
      return 'https://www.youtube.com/embed/'+id;
    }
  },
  mounted() {
    this.$refs.videoUrl.focus();
  },
  methods: {
    modalOpenVideo () {
      this.$modal.show('modal-post03');
    },
    modalVideoOpened () {
      this.$refs.videoUrl.focus();
    },
    addYoutube () {
      // console.log(this.videoUrl);
      // this.$modal.hide('modal-post03');
      // this.attachments.push(this.videoUrl);
      this.$emit('postVideo', this.videoUrl);
      this.videoUrl = '';
    },
    inputVideoUrl () {
      this.$v['videoUrl'].$touch();
    }
  },
};
</script>

<style lang="scss" scoped>

  #wrap {
    .form-group--error {
      input, select, textarea {
        border: 1px solid #ffd3ce;
        background-color: #ffd3ce;
      }
    }
    .error {
      font-size: 10rem;
      color: #ff7666;
      margin: 5px 0 -5px;
    }

    button.disabled {
      opacity: .5;
    }
  }

</style>
