<template>
  <form>
    <div class="modal-content">
      <ul class="bt_post_material">
        <li v-if="attachments.length < 4">
          <label class="upload-content-space user-file default" @click="expandItem = false">
            <input class="file-button" type="file" accept="image/*,video/*,application/pdf" @change="uploadImage">
            <img src="@/assets/img/talk/icon_img_off.png" alt="画像投稿ボタン">
          </label>
        </li>
        <li v-if="attachments.length < 4">
          <a @click="modalOpenVideo"><img src="@/assets/img/talk/icon_movie_off.png" alt="動画投稿ボタン"></a>
        </li>
        <li v-if="!isIphone && attachments.length < 4">
          <label class="upload-content-space user-file default" @click="expandItem = false">
            <img src="@/assets/img/talk/icon_audio_off.png" alt="音声投稿ボタン">
          </label>
        </li>
        <li v-if="openSell && isArtist">
          <label class="upload-content-space user-file default" :class="{disabled: disabledItemSelector}" @click="clickItemSelector">
            <img src="@/assets/img/buy/icon_item_off.png" alt="商品投稿ボタン">
          </label>
        </li>
        <li class="tgl-area" v-if="validPremiumSwitch">プレミア会員限定公開
          <input class="tgl tgl-light" id="cb1" type="checkbox" v-model="isPremiumOnly" :disabled="!validPremiumSwitch">
          <label class="tgl-btn" for="cb1"></label>
        </li>
      </ul>
      <div class="">
        <textarea ref="message" rows="5" cols="" placeholder="メッセージを入力してください" v-model="message"></textarea>
      </div>
      <div :class="{post_length: true, post_length_alert: message.length > MAX_MESSAGE_SIZE}">
        {{ message.length }} / {{ MAX_MESSAGE_SIZE }}
      </div>
      <!--
      <div class="post_text01">※画像、Youtubeへのリンク、音声を最大4点まで添付できます</div>
      -->
      <ul class="post_material" v-if="attachments.length > 0">
        <PostAttachmentThumb
            v-for="n in 4" :key="n"
            :attachment="attachments[n - 1]"
            :file="files[n - 1]"
            :talk_room="talk_room"
            @modalOpenPreview="$emit('modalOpenPreview', attachments[n - 1])"
            @removeAttachment="removeAttachment(n)"
            @update:isDigitalItem="updateDigitalItem(n - 1, $event)"
        ></PostAttachmentThumb>
      </ul>

      <div v-if="isArtist && expandItem">
        <div class="ta_c txt_16 bold mtb_20">お知らせする商品を決める</div>
        <div v-for="item in items" :key="item.id">
          <ul class="flex between align_c" @click="toggleItem(item)">
            <li>
              <div class="item">
                <ul class="col2">
                  <li class="col2L">
                    <div class="item_photo">
                      <img src="@/assets/img/signup/add_photo2.png" alt="商品画像" v-if="item.item_photos.length === 0">
                      <img :src="item.item_photos[0].image | imageUrl" alt="商品画像" v-if="item.item_photos.length > 0">
                    </div>
                  </li>
                  <li class="col2R">
                    <div class="item_title">{{ item.name }}</div>
                    <div class="item_text">{{ item.detail | truncate }}</div>
                    <ul class="col2 mb_10">
                      <li class="item_shipping">送料込</li>
                      <li class="item_price">{{ item.price | currency }}<span>税込</span></li>
                    </ul>
                    <div class="item_volume">数量：{{ item.value }}</div>
                  </li>
                </ul><!--.col2-->
              </div><!-- .item -->
            </li>
            <li class="icon_area">
              <img src="@/assets/img/buy/icon_check_off.png" alt="チェックオンアイコン画像" v-if="selectedItems.indexOf(item) === -1">
              <img src="@/assets/img/buy/icon_check_on.png" alt="チェックオンアイコン画像" v-if="selectedItems.indexOf(item) > -1">
            </li>
          </ul>
          <hr class="item_hr mtb_20">
        </div>
        <div v-if="!items.length">
          商品が登録されていません。<RouterLink to="/items/create/">販売申請</RouterLink>が受理されると商品が選択できます。
        </div>
      </div>

      <div v-if="isArtist && digital_items.indexOf(true) > -1">
        <div class="ta_c txt_16 bold mt_20 mb_10">値段を決める
          <button type="button" class="p-menu-browse-detail-tooltip c-tooltip c-tooltip-button c-tooltip--nowrap">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
            <span class="c-tooltip__contents">
                <span class="c-tooltip__text">
                  ステキナセカイでは決済手数料としまして1件につき¥300をいただいております。<br>
                  デジタルコンテンツ販売価格は¥600~を推奨させていただいております。
                </span>
            </span>
          </button>
        </div>
        <div id="digicon_price_area">
          <input type="number" id="digicon_price" placeholder="600" v-model="price">
        </div>
        <hr class="item_hr mtb_5">
        <div id="digicon_rule">
          <div class="formCheckbox">
            <input type="checkbox" id="accept" v-model="accept">
            <label for="accept" class="checkbox">
              <strong>ステキナセカイでは決済手数料としまして1件につき¥300をいただいております。</strong><br>
              デジコン投稿ルールを必ず読んでください。<br>ルールに従って投稿してください。</label>
          </div>
        </div>
      </div>

      <div class="bt_yes_no">
        <div class="bt_cancel"><a @click="$modal.hide('modal-post01');expandItem=false;">キャンセル</a></div>
        <div class="bt_form">
          <button type="button" value="send" @click="sendMessage"
                  :class="{disabled: !this.message && this.attachments.length === 0 || this.message.length > MAX_MESSAGE_SIZE}"
                  :disabled="!this.message && this.attachments.length === 0 || this.message.length > MAX_MESSAGE_SIZE">
            送信
          </button>
        </div>
      </div>
    </div>
    <modal class="modal-wrapper" name="modal-post03" :adaptive="true"
           height="auto" width="95%" :maxWidth="600" :styles="{borderRadius:'10px'}">
      <PostYoutube @postVideo="postVideo"></PostYoutube>
    </modal>
  </form>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import PostYoutube from '@/components/TalkRoom/PostYoutube';
import PostAttachmentThumb from '@/components/TalkRoom/PostAttachmentThumb';
import {validationMixin} from 'vuelidate';
import {required, maxLength, helpers} from 'vuelidate/lib/validators';

const regexpYoutube = /^https:\/\/www\.youtube\.com\/watch\?([^&]+)/i;
const regVideoUrl = helpers.regex('videoUrl', regexpYoutube);

export default {
  name: 'Post',
  components: {PostYoutube, PostAttachmentThumb},
  props: {
    talk_room: Object,
    socket: Object,
  },
  data() {
    return {
      message: '',
      messageValid: 0,  // @todo メッセージのバリデートまだ作っていない
      isPremiumOnly: false,
      attachments: [],
      files: [],
      videoUrl: '',
      videoUrlValid: 0,
      MAX_MESSAGE_SIZE: 1000,
      isIphone: false,
      expandItem: false,
      items: [],
      selectedItems: [],
      price: 600,
      digital_items: [false, false, false, false],
      accept: false,
      openSell: true,
    };
  },
  mixins: [validationMixin],
  validations() {
    return {
      videoUrl: {
        required,
        maxLength: maxLength(200),
        regVideoUrl,
      },
    };
  },
  computed: {
    chat_id () {
      // return this.$route.params.chat_id;
      return this.$route.params.chat_id;
    },
    /**
     * ■プレミアム会員向け投稿権限
     ・運営者（＝公式バッジの付いたユーザー）
     ・トークルームに紐づくマスターに属するマスターユーザー
     * @returns {boolean}
     */
    validPremiumSwitch () {
      if (!this.hasPremiumPaymentPlan) return false;
      let user_type = 'normal';
      if (this.talk_room.artist_id === this.$store.state.loginUser.artist_id) {
        user_type = 'artist';
      }
      if (this.$store.state.loginUser.is_official) {
        user_type = 'official';
      }
      return user_type === 'artist' || user_type === 'official';
    },
    isArtist () {
      return (this.talk_room.artist_id === this.$store.state.loginUser.artist_id)
    },
    subscription_type () {
      const user_talk_room = this.$store.state.loginUser.talk_rooms.find(talk_room => talk_room.id === this.talk_room.id);
      return user_talk_room.subscription.type;
    },
    hasPremiumPaymentPlan () {
      return !!this.talk_room.payment_plans.find(plan => plan.type === 'premium');
    },
    disabledItemSelector () {
      // 画像、動画、音声が一つでも登録されていれば商品登録ボタンを押せなくする
      return (this.attachments.length > 0);
    },
    attachmentsFilteredImages () {
      return this.attachments.filter(attachment => { return attachment.slice(0, 23) !== 'https://www.youtube.com' })
    },
  },
  filters: {
    toEmbedVideoUrl(url) {
      const getParameterByName = (name, url) => {
        let queryString = url.split('?');
        if (queryString.length >= 2) {
          let paras = queryString[1].split('&');
          for (let i = 0; i < paras.length; i++) {
            let eachPara = paras[i].split('=');
            if (eachPara[0] == name) return eachPara[1];
          }
        }
      };
      const id = getParameterByName('v', url);
      return 'https://www.youtube.com/embed/' + id;
    },
  },
  created () {
    this.isIphone = /iP(hone|(o|a)d)/.test(navigator.userAgent)
  },
  mounted() {
    if (this.$store.state.talkRoom.selectedRemark) {
      // メッセージを編集
      this.message = this.$store.state.talkRoom.selectedRemark.message;
      this.attachments = this.$store.state.talkRoom.selectedRemark.attachments.concat();
      // this.attachments = this.array_pad(this.$store.state.talkRoom.selectedRemark.attachments, 4, null);
      this.isPremiumOnly = this.$store.state.talkRoom.selectedRemark.isPremiumOnly;
    }
    this.$refs.message.focus();
  },
  methods: {
    async clickItemSelector() {
      if (this.disabledItemSelector) return;
      const response = await this.$http.get('items');
      this.items = response.data;
      this.expandItem = true;
    },
    async sendMessage() {
      let errorTxt = '';
      if (!this.message && this.attachments.length === 0) return;
      if (this.digital_items.indexOf(true) > -1) {
        if (!this.accept) {
          errorTxt += 'デジコン投稿ルールを読んだらチェックボックスをチェックしてください。';
        }
        if (this.price === undefined) {
          errorTxt += '値段を決めてください。';
        }
        const price = parseInt(this.price);
        console.log(price, Number.isFinite(price), price < 50, price > 30000);
        if (!Number.isFinite(price) || price < 50 || price > 30000) {
          errorTxt += '値段を50円～30000円の範囲にしてください';
        }
        if (errorTxt) {
          this.$swal('送信できませんでした', errorTxt, 'error');
          return;
        }
      }
      const params = this.makeMessageParam();
      this.message = '';
      this.$modal.hide('modal-post01');
      this.$store.commit('setLoading');

      const response = await axios.post(`https://${process.env.VUE_APP_CHAT_SERVER}/messages`, params);
      this.$store.commit('resetLoading');
      params.attachments = response.data.attachments;
      this.socket.emit('message', params);
    },
    makeMessageParam() {
      const params = {
        talk_room_id: this.talk_room.id,
        user: {
          id: this.$store.state.loginUser.id,
          name: this.$store.state.loginUser.nick,
          image: this.$store.state.loginUser.image,
        },
        send_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        message: this.message,
        comment_count: 0,
        like_count: 0,
      };
      // 投稿の編集
      if (this.$store.state.talkRoom.selectedRemark) {
        params.id = this.$store.state.talkRoom.selectedRemark.id;
        params.send_at = this.$store.state.talkRoom.selectedRemark.send_at;
        params.modified_at = moment().format();
      } else {
        // 新規発言はメッセージIDを新規作成
        params.id = 'cht_' + this.getUniqueStr();
      }
      // 投稿へのコメント
      if (this.chat_id) {
        params.parent_id = this.chat_id;
      }
      // プレミアム限定公開
      if (this.isPremiumOnly) {
        params.isPremiumOnly = true;
      }
      // 発言者のステータス
      if (this.$store.state.loginUser.artist_id === this.talk_room.artist_id) {
        params.user_status = 'artist';
      } else if (this.$store.state.loginUser.is_official) {
        params.user_status = 'secretariat';
      } else if (this.subscription_type === 'premium') {
        params.user_status = 'premium_user';
      }
      // 添付ファイル
      if (this.attachments) {
        params.attachments = this.attachments;
      }
      // 物販
      if (this.selectedItems.length) {
        params.items = [];
        let item;
        for (let i = 0; i < this.selectedItems.length; i++) {
          item = {};
          item.id = this.selectedItems[i].id;
          item.name = this.selectedItems[i].name;
          item.price = this.selectedItems[i].price;
          item.detail = this.selectedItems[i].detail;
          item.item_photos = this.selectedItems[i].item_photos.map(photos => photos.image);
          params.items.push(item);
        }
      }
      // デジコン
      if (this.digital_items.indexOf(true) > -1) {
        params.digital_items = this.digital_items;
        params.price = Number(this.price);
      }

      // プレミアムユーザーか
      if (!params.user_status && this.talk_room.payment_plans.length) {
        const status = `${this.talk_room.payment_plans[0].type}_user`;
        params.user_status = status;
      }

      return params;
    },
    getUniqueStr(myStrong) {
      var strong = 1000;
      if (myStrong) strong = myStrong;
      return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
    },
    async uploadImage(event) {
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];

      if (file.type.match('application/pdf')) {
        this.fileType = 'pdf';
        console.log(this.fileType)
      }

      const error_message = this.checkFileImage(file);
      if (!error_message) {
        this.files.push(file);
        const picture = await this.getBase64(file);
        this.attachments.push(picture);
      } else {
        this.$toasted.error(error_message, {
          position: 'top-center',
          duration: 2000,
        });
      }
    },
    async uploadAudio(event) {
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];

      const error_message = this.checkFileAudio(file);
      if (!error_message) {
        const picture = await this.getBase64(file);
        this.attachments.push(picture);
      } else {
        this.$toasted.error(error_message, {
          position: 'top-center',
          duration: 2000,
        });
      }
    },
    checkFileImage(file) {
      const SIZE_LIMIT = 1024*1024*200; // 200MB
      // ローカルマシンからの読み込みをキャンセルしたら処理中断
      if (!file) {
        return 'ファイルがみつかりません';
      }
      // jpeg か png 関連ファイル以外は受付けない
      if (
          file.type !== 'image/jpeg' &&
          file.type !== 'image/png' &&
          file.type !== 'image/gif' &&
          file.type !== 'image/svg+xml'
          &&
          file.type !== 'video/mp4' &&
          file.type !== 'video/mpeg' &&
          file.type !== 'video/webm' &&
          file.type !== 'video/ogg' &&
          file.type !== 'video/quicktime' &&
          file.type !== 'video/x-msvideo' &&
          file.type !== 'video/avi' &&
          file.type !== 'application/pdf' // PDFのMIMEタイプを追加
      ) {
        console.log(file);
        return 'ファイルが許可された形式(.jpg, .png, .gif, .svg, .mp4, .mov, .qt, .avi, .mpg, .mpeg, .webm, .ogv, .pdf)でありません';
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        return 'ファイル容量制限（200M）を超えています';
      }
      return false;

    },
    checkFileAudio(file) {
      const SIZE_LIMIT = 5000000; // 5MB
      // ローカルマシンからの読み込みをキャンセルしたら処理中断
      if (!file) {
        return 'ファイルがみつかりません';
      }
      // audio関連ファイル以外は受付けない
      if (!/audio\/(.*)/.test(file.type)) {
        return 'ファイルが許可された形式(音声ファイル)でありません';
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        return 'ファイル容量制限（5M）を超えています';
      }
      return false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    array_pad(input, padSize, padValue) { // eslint-disable-line camelcase
      //  discuss at: https://locutus.io/php/array_pad/
      // original by: Waldo Malqui Silva (https://waldo.malqui.info)
      //   example 1: array_pad([ 7, 8, 9 ], 2, 'a')
      //   returns 1: [ 7, 8, 9]
      //   example 2: array_pad([ 7, 8, 9 ], 5, 'a')
      //   returns 2: [ 7, 8, 9, 'a', 'a']
      //   example 3: array_pad([ 7, 8, 9 ], 5, 2)
      //   returns 3: [ 7, 8, 9, 2, 2]
      //   example 4: array_pad([ 7, 8, 9 ], -5, 'a')
      //   returns 4: [ 'a', 'a', 7, 8, 9 ]
      let pad = [];
      const newArray = [];
      let newLength;
      let diff = 0;
      let i = 0;
      if (Object.prototype.toString.call(input) === '[object Array]' && !isNaN(padSize)) {
        newLength = ((padSize < 0) ? (padSize * -1) : padSize);
        diff = newLength - input.length;
        if (diff > 0) {
          for (i = 0; i < diff; i++) {
            newArray[i] = padValue;
          }
          pad = ((padSize < 0) ? newArray.concat(input) : input.concat(newArray));
        } else {
          pad = input;
        }
      }
      return pad;
    },
    modalOpenVideo() {
      this.expandItem = false;
      this.$modal.show('modal-post03');
    },
    postVideo(videoUrl) {
      this.$modal.hide('modal-post03');
      this.attachments.push(videoUrl);
    },
    toggleItem(item) {
      this.selectedItems = [item];
      // if (this.selectedItems.indexOf(item) > -1) {
      //   this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      // } else {
      //   this.selectedItems.push(item);
      // }
    },
    updateDigitalItem(index, checked){
      this.$set(this.digital_items, index, checked);
    },
    removeAttachment(n) {
      this.attachments.splice(n - 1, 1);
      this.files.splice(n - 1, 1);
    },
  },
};
</script>

<style lang="scss" scoped>

.disabled {
  opacity: .3;
}
.user-file {
  cursor: pointer;
  outline: none;
}

.user-file.default {
}

.user-file.default:hover {
}

.user-file.default:active {
}

.user-file-image {
  max-width: 85px;
  display: block;
}

.user-file-image:hover {
  opacity: 0.8;
}

.file-button {
  display: none;
}

.uploaded {
  align-items: center;
  display: flex;
}

.post_material {
  img, iframe, audio {
    width: 100%;
  }
}

.modal-content {
  overflow:auto;
}

#wrap input, #wrap select, #wrap textarea {
  width: 100%;
  font-size: 10rem;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 15px 20px;
  background-color: #E6F5F5;
  vertical-align: baseline;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: none;
}
#wrap input:focus, #wrap select:focus, #wrap textarea:focus {
  border: 1px solid #1ba79a;
  box-shadow: 0 0 8px 1px rgb(27 167 154 / 30%);
  outline: 0;
}
#digicon_price_area {
  text-align: center
}
#wrap #digicon_price {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 80%;
  text-align: center;
}

/* ツールチップの基本スタイル */
.c-tooltip {
    position: relative;
    display: inline-block;
}

.c-tooltip .c-tooltip__contents {
    visibility: hidden;
    width: 200px;
    background-color: #0c0c0c;
    color: #fff;
    font-size: 12px;
    text-align: left;
    border-radius: 6px;
    padding: 9px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -107px;
    opacity: 0;
    transition: opacity 0.3s;
}

.c-tooltip .c-tooltip__contents::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 9px;
    border-style: solid;
    border-color: #0c0c0c transparent transparent transparent;
}

/* ホバー時のスタイル */
.c-tooltip:hover .c-tooltip__contents {
    visibility: visible;
    opacity: 1;
}

/* ボタンおよびアイコンの枠線を消す */
.p-menu-browse-detail-tooltip, .fa-question-circle {
    border: none;
}

/* もしボタンにpaddingやbackgroundなどのスタイルがあれば、それも必要に応じて調整する */
.p-menu-browse-detail-tooltip {
    background: transparent;
    padding-left: 5px;
}

</style>