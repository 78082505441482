<template>
  <div class="tab_area" v-bind:class="{ isMargin: nowSellItems.length === 0 }">
      <label @click="clickAll" :class="{active: messagesFiltering === 'all', isBannerClass: nowSellItems && nowSellItems.length }"><div class="tab-area-list">みんな</div></label>
      <label @click="clickOnlyArtist" :class="{active: messagesFiltering === 'only_artist', isBannerClass: nowSellItems && nowSellItems.length}"><div class="tab-area-list">マスター</div></label>
      <label @click="clickArtistMine" :class="{active: messagesFiltering === 'artist_mine', isBannerClass: nowSellItems && nowSellItems.length}"><div class="tab-area-list">わたし</div></label>
  </div>
</template>

<script>
export default {
  name: 'FilterTab',
  components: {  },
  props: {
    messagesFiltering: String,
    nowSellItems: Array,
  },
  data () {
    return {
      content: ''
    }
  },
  computed: {
  },
  filters: {
  },
  methods: {
    clickAll () {
      this.$emit('update', 'all');
    },
    clickOnlyArtist () {
      this.$emit('update', 'only_artist');
    },
    clickArtistMine () {
      this.$emit('update', 'artist_mine');
    },
  },
}
</script>

<style lang="scss" scoped>

#wrap {
  padding: 0;

  .tab_area label.active {
    background: #edebeb;
    color: black;
  }
}
.tab-area-list {
  position: absolute;
  bottom: 12%;
  left: 48%;
  top: 5px;
  transform: translateX(-30%);
  margin-bottom: 5px;
}
.isBannerClass {
  padding-bottom: 33px;
}
.isMargin {
  margin: 50px auto 10px;
}

</style>


