<template>
  <div>
    <transition name="slide">
      <section class="buyBannerArea" v-if="showBanner">
        <ul class="col2">
          <li class="col2L">
            <div class="banner_thumb">
              <img src="@/assets/img/mypage/file_upload.png" alt="バナーサムネイル画像" v-if="!nowSellItem.item_photos.length">
              <img :src="nowSellItem.item_photos[0].image | imageUrl" alt="バナーサムネイル画像" v-if="nowSellItem.item_photos.length">
            </div>
          </li>
          <li class="col2R">
            <div class="buy_title">{{ nowSellItem.name }}</div>
            <ul class="col2">
              <li class="buy_timestamp">
                <span>NEW</span>
                {{ nowSellItem.authorized_at | formatDate }}
              </li>
              <li class="buy_bt">
                <a @click="$emit('modalOpenBuy', nowSellItem)">
                  <img src="@/assets/img/buy/icon_cart_banner.png" alt="カートアイコン">購入する
                </a>
              </li>
            </ul>
          </li>
        </ul><!--.col2-->
      </section>
    </transition>
  </div>

</template>

<script>
import moment from 'moment';
export default {
  props: {
    talkRoom: Object,
    nowSellItems: Array,
  },
  data () {
    return {
      showBanner: false,
    }
  },
  computed: {
    nowSellItem () {
      return this.nowSellItems[0];
    }
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD');
    }
  },
  mounted() {
    setTimeout(() => {
      this.showBanner = true;
    }, 500);
  }
}
</script>
<style>
.slide-enter-active, .slide-leave-active {
  transition: all .5s ease;
}
.slide-enter, .slide-leave-to {
  transform: translateY(-100%);
}
</style>
