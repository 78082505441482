<template>
  <div class="modal-window">
    <div class="modal-content">
      <ul class="post_edit">
        <li>
          <a @click="editPost" :class="{disabled: disabledEdit}">
            <img src="@/assets/img/talk/icon_edit.png" alt="メッセージ編集アイコン">メッセージを編集する
          </a>
        </li>
        <li>
          <a @click="$emit('openModalPostDelete')" :class="{disabled: isMineSelectedRemark !== 1}">
            <img src="@/assets/img/talk/icon_delete.png" alt="メッセージ削除アイコン">メッセージを削除する
          </a>
        </li>
        <li>
          <a @click="$emit('openModalPostReport')" :class="{disabled: isMineSelectedRemark === 1}">
            <img src="@/assets/img/talk/icon_report.png" alt="メッセージ通報アイコン">メッセージを通報する
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalPostEdit',
  props: {
    selectedRemark: Object,
    isMineSelectedRemark: {
      type: Number,
      default: -1,
    }
  },
  computed: {
    disabledEdit () {
      return this.isMineSelectedRemark !== 1 ||
          (this.selectedRemark.items && this.selectedRemark.items.length > 0) ||
          (this.selectedRemark.digital_items && this.selectedRemark.digital_items.length > 0)
    }
  },
  methods: {
    editPost () {
      if (!this.disabledEdit) {
        this.$emit('editPost');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: .3;
}

.vm--modal {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px;

  .modal-window {
    padding: 10px;
  }
}
</style>