<template>
  <div class="modal-window">
    <div class="modal_buy_head">購入する</div>
    <a class="modal-buy-close" @click="$emit('modalClose')"><img src="@/assets/img/buy/bt_close.png" alt="閉じるボタン画像"></a>
    <div class="modal-buy-content">
      <BuyModalItem :item="selectedItem" @update:cartValue="updateCartValue(selectedItem, $event)" :min="1"></BuyModalItem>
      <div class="bt_form mb_5"><a @click="next">購入手続きへ</a></div>

      <div class="other_buy">
        <div class="other_buy_title">その他の商品</div>
        <BuyModalItem :item="otherItem" v-for="otherItem in otherItems" :key="otherItem.id" @update:cartValue="updateCartValue(otherItem, $event)" :min="0"></BuyModalItem>
        <div class="bt_form mb_5"><a @click="next">購入手続きへ</a></div>
      </div><!--.other_buy-->

    </div>
    <div class="modal_buy_foot"></div>
  </div>
</template>

<script>
import BuyModalItem from '@/components/TalkRoom/BuyModalItem'
export default {
  components: {
    BuyModalItem
  },
  props: {
    nowSellItems: Array,
    selectedItemId: Number,
  },
  computed: {
    otherItems () {
      return this.nowSellItems.filter(item => {
        return (item.id !== this.selectedItemId)
      });
    },
    selectedItem () {
      return this.nowSellItems.find(item => item.id === this.selectedItemId);
    }
  },
  methods: {
    next () {
      // 選択した商品が配列の先頭にくるよう選択商品を先にカートに入れる
      this.$store.commit('setCart', [this.selectedItem].filter(item => item.cartValue));
      this.otherItems.filter(item => item.cartValue).forEach(item => {
        this.$store.commit('addCart', item);
      });
      this.$router.push({name: 'cart-create'});
    },
    updateCartValue (item, value) {
      const selectedItem = this.nowSellItems.find(obj => obj.id === item.id);
      const idx = this.nowSellItems.indexOf(selectedItem);
      selectedItem.cartValue = value;
      this.$emit('updateSellItemCartValue', idx, value);

    }
  },
}
</script>
