<template>
  <ul class="modal_buy_area col2 mb_30">
    <BuyModalPhoto class="col2L" :photos="item_photos"></BuyModalPhoto>
    <li class="col2R">
      <div class="buy_title">{{ item.name }}</div>
      <div class="buy_text">
        {{ item.detail | truncate }}</div>
      <ul class="col2 mb_10">
        <li class="shipping">送料込</li>
        <li class="price">{{ item.price | currency }}<span>税込</span></li>
      </ul>
      <ul class="col3">
        <li>
          <img src="@/assets/img/buy/bt_minus.png" alt="マイナスボタン"
               @click="$emit('update:cartValue', Math.max(min, item.cartValue - 1))">
        </li>
        <li>{{ item.cartValue }}</li>
        <li>
          <img src="@/assets/img/buy/bt_plus.png" alt="プラスボタン"
               @click="$emit('update:cartValue', Math.min( Math.min(10, item.value), item.cartValue + 1) )">
        </li>
      </ul>
      <ul class="text-center">
        <li class="">購入総額:<span>{{ now_sell_total_price }}円</span></li>
        <!-- 在庫の1/2で表示(20230521) -->
        <li class="">目標個数:<span>{{ target_Qty }}個</span></li>
        <!-- 在庫の1/2 * 商品金額で表示(20230521) -->
        <li class="">目標金額:<span>{{ target_price }}円</span></li>
        <li class="">サポーター:<span>{{ target_supporter }}人</span></li>
        <!-- 月単位で算出 -->
        <li class="">残り:<span>{{ remainingDays }}日</span></li>
      </ul>
    </li>
  </ul><!--.col2-->
</template>

<script>
import BuyModalPhoto from '@/components/TalkRoom/BuyModalPhoto'
export default {
  components: {BuyModalPhoto},
  data() {
    return {
      remainingDays: 0,
    };
  },
  mounted() {
    this.calculateRemainingDays();
  },
  methods: {
    calculateRemainingDays() {
      const today = new Date();
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const remainingDays = lastDayOfMonth.getDate() - today.getDate() + 1;
      this.remainingDays = remainingDays;
    },
  },
  props: {
    item: Object,
    min: Number,
  },
  computed: {
    item_photos () {
      return this.item.item_photos.map(photo => photo.image);
    },
    now_sell_total_price() {
      let totalPrice = 0;
      this.item.carts.map((carts) => {
        totalPrice += carts.total_price
      });
      return totalPrice;
    },
    target_Qty() {
      var targetQty = 0;
      targetQty = this.item.value / 2;
      return targetQty;
    },
    target_price() {
      return this.item.price * this.target_Qty;
    },
    target_supporter() {
      if(this.now_sell_total_price == 0) {
        return 0;
      } else {
        let targetSupporterCount = 0;
        this.item.carts.map((carts, index) => {
          targetSupporterCount = index;
        });
        return targetSupporterCount + 1;
      }
    },
  },
}
</script>

<style>
ul.text-center {
  width: 70%;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #252525; 
  border-radius: 15px;
}

ul.text-center li {
  margin: 0 15px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #aaa5a5;
}

ul.text-center li span {
  display: block;
  font-weight: bold;
  color: #f5efef;
  margin-left: auto;
}

ul.text-center  {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  ul.text-center {
    width: 100%;
  }
  ul.text-center li span {
    font-size: 2px;
  }
  ul.text-center li {
    font-size: 2px;
  }
}

</style>